import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import WcIcon from "@mui/icons-material/Wc";
import CelebrationIcon from "@mui/icons-material/Celebration";
import CakeIcon from "@mui/icons-material/Cake";
import InstagramIcon from "@mui/icons-material/Instagram";
import RollerSkatingIcon from "@mui/icons-material/RollerSkating";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
// import { ReactComponent as Store } from "../../assets/store.svg";
import { style, TextBox, TransactionCard } from "../../styles/styledComponent";
import { Close, Edit } from "@mui/icons-material";
import { getProfile, updateProfile } from "../../service/profile";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useAppConfig from "../../hooks/useAppConfig";
import {
  isProfileSurveyEnabled,
  onProfileClickRedirect,
} from "../../shared/components/ListOfLinks";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { maritalStatus } from "../DynamicForm/constants";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import QRCodeDialog from "./QRCode";
import BarCodeDialog from "./BarCode";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AdditionalTextContent from "./AdditionalText";
import _, { startCase } from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

const dateFormatFields = ["anniversary", "dob"];

const icons = {
  mobile: <PhoneIcon />,
  email: <MailIcon />,
  dob: <CakeIcon />,
  gender: <PeopleAltIcon />,
  maritalStatus: <WcIcon />,
  anniversary: <CelebrationIcon />,
  shoeSizes: <RollerSkatingIcon />,
  instagramId: <InstagramIcon />,
}

const validationUserProfile = (mandatoryFields) => Yup.object().shape({
  name: mandatoryFields.name ? Yup.string().required('Must be required').min(3, "Must be at least 3 characters") : Yup.string(),
  email: mandatoryFields.email ? Yup.string().required('Must be required').email("Invalid Email") : Yup.string(),
  gender: mandatoryFields.gender ? Yup.string().required("Must br required") : Yup.string(),
  maritalStatus: mandatoryFields.maritalStatus ? Yup.string().required("Must br required") : Yup.string(),
  dob: mandatoryFields.dob ? Yup.string().required("Must br required") : Yup.string(),
  anniversary: mandatoryFields.anniversary ? Yup.string().required("Must br required") : Yup.string(),
});

const UserProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const pwaConfig = useAppConfig();
  const [isQRCode, setIsQRCode] = useState(false);
  const [isBarCode, setIsBarCode] = useState(false);
  const isAdditionalText = _.get(pwaConfig, "appConfig.config.additionalText.ALL_PAGES.html") === "<p><br></p>";
  const loyaltyForm = _.get(pwaConfig, "appConfig.config.loyaltySignUpForm");

  const auth = useAuth();
  const [profile, setProfile] = useState({
    mobile: "",
  });
  const [fields, setFields] = useState([]);
  const mandatoryFields = loyaltyForm.reduce((acc, val) => { return { ...acc, [val.name === "birthday" ? "dob" : val.name]: val.mandatory } }, {})

  const formik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: () => validationUserProfile(mandatoryFields),
    onSubmit: () => handleUpdate()
  });

  const {
    values,
    resetForm,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    touched,
    isValid,
    dirty,
  } = formik;

  useEffect(() => {
    resetForm({ values: {...profile, dob: profile.dob === null ? "" : profile.dob, anniversary: profile.anniversary === null ? "" : profile.anniversary} });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profile]);

  const callApiProfile = () => {
    getProfile().then((res) => {
      const data = loyaltyForm?.reduce((newObj, value) => {
        if(value.name === "birthday") {
          return { ...newObj, dob: res.dob };
        }
        return { ...newObj, [value.name]: res[value.name] };
      }, {});
      setProfile(data);
      resetForm(data);
      setFields(Object.keys(data))
    });
  };

  useEffect(() => {
    callApiProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setIsEdit(false);
    callApiProfile();
  };
  const handleUpdate = () => {
    const data = {
      data: {
        customerMobile: values.mobile,
        customerName: values.name,
        dob: values.dob && new Date(values.dob).toISOString(),
        anniversary: values.anniversary && new Date(values.anniversary).toISOString(),
        gender: values.gender,
        email: values.email,
        maritalStatus: values.maritalStatus,
      },
      tenant: auth?.user?.tenantId,
    };
    updateProfile(data)
      .then(() => toast.success("Profile Updated Successfully"))
      .catch((err) => toast.error(err.message));
    setIsEdit(false);
  };

  const maskEmail = (email) => {
    if(email?.split("@").length === 1) {
      return email
    }
    const [localPart, domainPart] = email?.split("@") || "";

    const maskedLocal =
      localPart?.length > 1
        ? localPart.slice(0, 1) +
          "x".repeat(localPart.length - 1)
        : localPart;

    const [domainName, domainTLD] = domainPart?.split(".") || "";
    const maskedDomain =
      domainName?.length > 1
        ? domainName.slice(0, 1) +
          "x".repeat(domainName.length - 1) +
          "." +
          domainTLD
        : domainName + "." + domainTLD;

    return `${maskedLocal}@${maskedDomain}`;
  };

  const getValue = (name, value) => {
    if(dateFormatFields.includes(name)) {
      return value ? moment(`${value}`).format("DD-MM-YYYY") || "Not Available" : "Not Available";
    }
    if(name === "mobile" && value?.length) {
      return value.slice(0, 2) + 'x'.repeat(value.length - 4) + value.slice(-2);
    }
    if(name === "email") {
      return value ? maskEmail(value) : "Not Available";
    }
    return value || "Not Available";
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return true;
    }
    return false;
  }

  return (
    <>
      <TransactionCard className="userProfile">
        <Typography fontWeight={800} fontSize={20} sx={{ textAlign: "center" }}>
          Hi, {values.name || "User"} !
        </Typography>
        <Edit
          className="editIcon"
          fontSize="small"
          onClick={() => setIsEdit(true)}
        />
        <Box className="profile">
        {Object.entries(values).map(([name, value], i) => {
          const fieldName = name === "dob" ? "birthday" : name
          const field = loyaltyForm.find((field) => field.name === fieldName);
          if(name === "NikeComponent") {
            return "";
          }
          if(name !== "name" && field) {
            return (
              <Box className="profileContent" key={i}>
              <Typography color="primary" className="profileIcon">{icons[name] || ""}</Typography>
              <Box className="profileContainer" key={name}>
              <Typography color="primary" className="profileBox">
                {startCase(field?.label.toLocaleLowerCase())}
              </Typography>
              <Typography>{getValue(name, value)}</Typography>
              </Box>
              </Box>
            );
          }
          return "";
        })}
        </Box>
        <Box className="buttonContainer">
          <Button
            variant="outlined"
            startIcon={<QrCodeIcon color="primary" />}
            onClick={() => setIsQRCode(true)}
            className="defaultButton"
          >
            Show QR Code to Cashier
          </Button>
          <Button
            variant="outlined"
            startIcon={<ViewWeekIcon color="primary" />}
            onClick={() => setIsBarCode(true)}
            className="defaultButton"
          >
            Show Bar Code to Cashier
          </Button>
          {isProfileSurveyEnabled(pwaConfig) && (
            <Button
              variant="outlined"
              startIcon={<DriveFileRenameOutlineIcon color="primary" />}
              onClick={() => onProfileClickRedirect(pwaConfig, auth)}
              className="defaultButton"
            >
              Complete Profile
            </Button>
          )}
        </Box>
      </TransactionCard>

      {isQRCode && <QRCodeDialog onClose={() => setIsQRCode(false)} mobile={values.mobile} />}
      {isBarCode && <BarCodeDialog onClose={() => setIsBarCode(false)} mobile={values.mobile} />}

      <Modal open={isEdit} onClose={handleClose} component="form" onSubmit={handleSubmit} noValidate>
        <Box sx={style}>
          <IconButton
            style={{ position: "absolute", top: 10, right: 40 }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
          <Typography
            variant="h6"
            style={{ fontWeight: 600, marginBottom: 30 }}
          >
            Edit Profile
          </Typography>
          {fields.includes("name") && <TextBox
            label={loyaltyForm.find((field) => field.name === "name")?.label || ""}
            required={!!mandatoryFields.name}
            name="name"
            value={values.name}
            InputLabelProps={{
              shrink: true,
              style: { color: "black" },
            }}
            error={Boolean(errors.name && touched.name ? errors.name : null)}
            helperText={errors.name && touched.name ? errors.name : null}
            onChange={handleChange}
          />}
          {fields.includes("email") && <TextBox
            label={loyaltyForm.find((field) => field.name === "email")?.label || ""}
            required={!!mandatoryFields.email}
            name="email"
            value={values.email}
            InputLabelProps={{
              shrink: true,
              style: { color: "black" },
            }}
            error={Boolean(errors.email && touched.email ? errors.email : null)}
            helperText={errors.email && touched.email ? errors.email : null}
            onChange={handleChange}
          />}

          {fields.includes("gender") && <Box style={{ width: "82%", marginLeft: "9%" }}>
            <FormControl fullWidth required={!!mandatoryFields.gender}>
              <InputLabel id="select-label" style={{ color: "black" }}>
                Gender
              </InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={values.gender || ""}
                label={loyaltyForm.find((field) => field.name === "gender")?.label || ""}
                required={!!mandatoryFields.gender}
                onChange={(e) => setFieldValue("gender", e.target.value)}
                style={{ textAlign: "left" }}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Trans-Gender">Trans-Gender</MenuItem>
                <MenuItem value="Prefer not to Disclose">
                  Prefer not to Disclose
                </MenuItem>
              </Select>
            </FormControl>
          </Box>}

          {fields.includes("maritalStatus") && <Box style={{ width: "82%", marginLeft: "9%", marginTop: 25 }}>
            <FormControl fullWidth required={!!mandatoryFields.maritalStatus}>
              <InputLabel id="select-label" style={{ color: "black" }}>
                Marital Status
              </InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={values.maritalStatus || ""}
                label={loyaltyForm.find((field) => field.name === "maritalStatus")?.label}
                required={!!mandatoryFields.maritalStatus}
                onChange={(e) => setFieldValue("maritalStatus", e.target.value)}
                style={{ textAlign: "left" }}
                error={Boolean(errors.maritalStatus && touched.maritalStatus ? errors.maritalStatus : null)}
                helperText={errors.maritalStatus && touched.maritalStatus ? errors.maritalStatus : null}
              >
                {maritalStatus.map((status) => (
                  <MenuItem value={status?.value}>{status?.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>}

          <LocalizationProvider dateAdapter={AdapterMoment} fullWidth>
            {fields.includes("dob") && <Box style={{ marginTop: 25 }}>
              <TextField
                type="date"
                name="dob"
                style={{ width: "82%" }}
                label={loyaltyForm.find((field) => field.name === "birthday")?.label || ""}
                value={moment(values.dob).format("YYYY-MM-DD")}
                onChange={(event) => setFieldValue("dob", moment(event.target.value)?.format("YYYY-MM-DD").toString(), "")}
                required={!!mandatoryFields.dob}
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.dob && touched.dob ? errors.dob : null)}
                helperText={errors.dob && touched.dob ? errors.dob : null}
                inputProps={
                  {
                    min: '1900-01-01',
                    max: moment().format('YYYY-MM-DD'),
                  }
                }
              />
            </Box>}
            {fields.includes("anniversary") && <Box style={{ marginTop: 25 }}>
              <TextField
                type="date"
                name="anniversary"
                style={{ width: "82%" }}
                label={loyaltyForm.find((field) => field.name === "anniversary")?.label || ""}
                value={moment(values.anniversary).format("YYYY-MM-DD")}
                onChange={(event) => setFieldValue("anniversary", moment(event.target.value)?.format("YYYY-MM-DD").toString(), "")}
                required={!!mandatoryFields.anniversary}
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.anniversary && touched.anniversary ? errors.anniversary : null)}
                helperText={errors.anniversary && touched.anniversary ? errors.anniversary : null}
                inputProps={
                  {
                    min: '1900-01-01',
                    max: moment().format('YYYY-MM-DD'),
                  }
                }
              />
            </Box>}
          </LocalizationProvider>

          <Button
            style={{ marginTop: 30 }}
            variant="contained"
            type="submit"
            disabled={!isValid || !dirty || (values.email?.length && validateEmail(values.email))}
          >
            Update
          </Button>
        </Box>
      </Modal>
      {!isAdditionalText && <AdditionalTextContent />}
    </>
  );
};

export default UserProfile;
