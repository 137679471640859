import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import STRING_CONSTANTS from "../../../shared/constants/StringConstants";
import {
  // Activated,
  Active,
  ActiveBox,
  // GenericBox,
  OfferCard,
  OfferCode,
  OnlyforYouBox,
  Textval,
} from "../../../styles/styledComponent";
import { ReactComponent as Star } from "../../../assets/emojione_star.svg";
import moment from "moment";
import {
  // activateAvailableOffers,
  // activateOffer,
  getActivated,
  getActivationReady,
  // getVoucher,
} from "../../../service/offer.service";
// import { startCase } from "lodash";
import IfEmpty from "../../../shared/constants/IfEmpty";
import { ReactComponent as NoOffer } from "../../../assets/No_offers_ui.svg";

// import useAuth from "../../../hooks/useAuth";
import useAppConfig from "../../../hooks/useAppConfig";
const ActivatedOffers = () => {
  const [activated, setActivated] = useState([]);
  // const [activatedVoucher, setActivatedVoucher] = useState([]);
  const [activationReady, setActivationReady] = useState([]);
  // const [availableVoucher, setAvailableVoucher] = useState([]);
  // let today = moment(new Date()).format("yyyy-MM-DD");
 // const decodedData = useAuth();
  const Config = useAppConfig();
  const Business = Config?.appConfig?.selecteBussines?.name;
  /*let intialData = {
    id: decodedData.user.userId,
    activatePromotion: {
      ruleId: decodedData.user.campaignId,
      campaignId: decodedData.user.campaignId,
      date: new Date().toISOString(),
      data: { activatePromoIds: [] },
    },
  };*/
  // const [data, setData] = useState({ ...intialData });

  useEffect(() => {
    // getVoucher().then((response) => {
    //   // eslint-disable-next-line
    //   const data = response.filter((item) => {
    //     let expireDate = moment(item.expiryDate).format("yyyy-MM-DD");
    //     if (item.status === "activated" && expireDate > today) {
    //       return item;
    //     }
    //   });
    //   setActivatedVoucher(data);
    //   const availabledata = response.filter(
    //     (item) => item.status === "available"
    //   );
    //   setAvailableVoucher(availabledata);
    // });
    getActivated(Business).then((response) => setActivated(response));
    getActivationReady(Business).then((response) =>
      setActivationReady(response)
    );
  }, [Business]);
   /* const handleSubmit = (item) => {
    let payload = {
      ...data,
      activatePromotion: {
        ...data.activatePromotion,
        data: {
          activatePromoIds: [item],
        },
      },
    };
    setData(payload);

    activateOffer(payload, decodedData?.token).then((res) => {
      if (res.successActivations?.length > 0) {
        getActivationReady().then((resp) => {
          setActivationReady(resp);
        });
        setData(STRING_CONSTANTS.intialData);
      }
    });
  }; */
  // const handleAcivate = (val) => {
  //   let payloadData = { productGuIds: [val] };
  //   if (availableVoucher.length > 0)
  //     activateAvailableOffers(payloadData).then((res) => {
  //       if (res.status === "failure") {
  //       } else {
  //         getVoucher().then((resp) => {
  //           setAvailableVoucher(resp);
  //         });
  //       }
  //     });
  // };
  return (
    <div>
      {activated?.map((obj, index) => {
        return (
          <OfferCard key={index}>
            {obj.currentOffer.isCustomerSpecific === true && (
              <Box className="display">
                <OnlyforYouBox>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginLeft: 15,
                      marginTop: 5,
                    }}
                  >
                    <Star className="staricon" />
                    <Textval>{STRING_CONSTANTS.ONLY_FOR_YOU}</Textval>
                  </Box>
                </OnlyforYouBox>
              </Box>
            )}
            {/* <Box className="display"> */}
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }}
            >
              Offer Name:{" "}
              {obj?.currentOffer?.categoryInfo?.category
                ? obj.currentOffer.categoryInfo.category
                : "--"}
            </Typography>
            <Typography style={{ fontSize: 14, marginBottom: 10 }}>
              <b> {STRING_CONSTANTS.VALID_TILL}: </b>
              {moment(`${obj?.expiresOn}`).format("DD-MMM-YYYY")}
            </Typography>
            {/* </Box> */}
            <Typography style={{ fontSize: 14, marginBottom: 10 }}>
              <span style={{ fontWeight: 600 }}> Offer Description: </span>
              {obj?.currentOffer?.description}
            </Typography>
            <Box className="display">
              <OfferCode>
                {STRING_CONSTANTS.OFFER_CODE} {obj?.offerCode}
              </OfferCode>
              <ActiveBox>
                <Active>{STRING_CONSTANTS.ACTIVE}</Active>
              </ActiveBox>
            </Box>
          </OfferCard>
        );
      })}

      {/* {activatedVoucher?.map((obj, index) => {
        return (
          <OfferCard key={index}>
            <Box className="display">
              <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                {obj?.coupon.productName}
              </Typography>
              <Typography style={{ fontSize: 11 }}>
                {STRING_CONSTANTS.VALID_TILL}
                <b>{moment(`${obj?.expiryDate}`).format("DD-MM-YYYY")}</b>
              </Typography>
            </Box>

            <Typography style={{ fontSize: 14, marginBottom: 10 }}>
              {obj?.coupon.voucherName}
            </Typography>
            <Box className="display">
              <OfferCode>
                {STRING_CONSTANTS.VOUCHER_NO} {obj?.voucherNo}
              </OfferCode>
              <ActiveBox>
                <Active>{STRING_CONSTANTS.ACTIVE}</Active>
              </ActiveBox>
            </Box>
          </OfferCard>
        );
      })} */}

      {activationReady?.map((obj, index) => {
        return (
          <OfferCard key={index}>
            {obj.currentOffer.isCustomerSpecific === true && (
              <Box className="display">
                {/* <Typography variant="caption">
                {obj?.currentOffer?.offerBasedOn
                  ? startCase(
                      `${obj.currentOffer.offerBasedOn} ${STRING_CONSTANTS.OFFER_TYPE}`
                    )
                  : "Not Applicable"}
              </Typography> */}

                <OnlyforYouBox>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: 5,
                      marginLeft: 15,
                    }}
                  >
                    <Star className="staricon" />
                    <Textval>{STRING_CONSTANTS.ONLY_FOR_YOU}</Textval>
                  </Box>
                </OnlyforYouBox>
              </Box>
            )}
            {/* <Box className="display"> */}
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginBottom: 5 }}
            >
              Offer Name:{" "}
              {obj?.currentOffer?.categoryInfo?.category
                ? obj.currentOffer.categoryInfo.category
                : "--"}
            </Typography>
            <Typography style={{ fontSize: 14, marginBottom: 10 }}>
              <b> {STRING_CONSTANTS.VALID_TILL} </b>
              {moment(`${obj.currentOffer?.endDate}`).format("DD-MM-YYYY")}
            </Typography>
            {/* </Box> */}

            <Typography style={{ fontSize: 14, marginBottom: 10 }}>
              <span style={{ fontWeight: 600 }}> Offer Description: </span>

              {obj?.currentOffer?.description}
            </Typography>
            <Box className="display">
              <OfferCode>
                {STRING_CONSTANTS.OFFER_CODE} {obj?.offerCode}
              </OfferCode>
            </Box>
          </OfferCard>
        );
      })}

      {/* {availableVoucher?.map((obj, index) => {
        return (
          <OfferCard key={index}>
            <Box className="display">
              <GenericBox>
                <Box className="flexCenter">
                  <Star className="staricon" />
                  <Textval>{STRING_CONSTANTS.GENERIC_OFFER}</Textval>
                </Box>
              </GenericBox>
            </Box>
            <Box className="display">
              <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                {obj?.coupon.productName}
              </Typography>
              <Typography style={{ fontSize: 11 }}>
                {STRING_CONSTANTS.VALID_TILL}
                <b>{moment(`${obj?.expiryDate}`).format("yyyy-MM-DD")}</b>
              </Typography>
            </Box>

            <Typography style={{ fontSize: 14, marginBottom: 10 }}>
              {obj?.coupon.voucherName}
            </Typography>
            <Box className="display">
              <OfferCode>
                {STRING_CONSTANTS.VOUCHER_NO} {obj?.voucherNo}
              </OfferCode>
              <Activated
                variant="contained"
                onClick={() => handleAcivate(obj.coupon.productGuid)}
              >
                {STRING_CONSTANTS.ACTIVATE}
              </Activated>
            </Box>
          </OfferCard>
        );
      })} */}

      {activated?.length === 0 &&
        // activatedVoucher?.length === 0 &&
        activationReady?.length === 0 && (
          // availableVoucher?.length === 0 &&
          <IfEmpty
            Icon={NoOffer}
            head={STRING_CONSTANTS.EMPTY}
            desc={STRING_CONSTANTS.NO_OFFERS_FOUND}
          />
        )}
    </div>
  );
};

export default ActivatedOffers;
